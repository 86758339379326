import React from "react";

const PortfolioTable = () => {

    return (
        <div class="grid mb-8 border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 md:mb-12 md:grid-cols-3 bg-white dark:bg-gray-800">
    <figure class="flex flex-col items-center  p-8  bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e dark:bg-gray-800 dark:border-gray-700">
        <blockquote class=" mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">$AUR - Aurora Innovation</h3>
            <p class="my-4">Opened: &emsp; January 4, 2024 @ $1.03/share</p>
            <p class="my-4">C: &emsp; N/A</p>
        </blockquote>   
    </figure>


    <figure class="flex flex-col items-center  p-8  bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e dark:bg-gray-800 dark:border-gray-700">
        <blockquote class=" mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">$AUR - Aurora Innovation</h3>
            <p class="my-4">Opened: &emsp; January 4, 2024 @ $1.03/share</p>
            <p class="my-4">C: &emsp; N/A</p>
        </blockquote>   
    </figure>
    <figure class="flex flex-col items-center  p-8  bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e dark:bg-gray-800 dark:border-gray-700">
        <blockquote class=" mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">$AUR - Aurora Innovation</h3>
            <p class="my-4">Opened: &emsp; January 4, 2024 @ $1.03/share</p>
            <p class="my-4">C: &emsp; N/A</p>
        </blockquote>   
    </figure>
    <figure class="flex flex-col items-center  p-8  bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e dark:bg-gray-800 dark:border-gray-700">
        <blockquote class=" mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">$AUR - Aurora Innovation</h3>
            <p class="my-4">Opened: &emsp; January 4, 2024 @ $1.03/share</p>
            <p class="my-4">C: &emsp; N/A</p>
        </blockquote>   
    </figure>



    
    
    
</div>
    )
}

export default PortfolioTable;