import React from 'react';

const NotesSecured = () => {
  return (
    <div class='text-left'>
        <h2 className='text-xl font-medium'> 1/5/2023</h2>
        <p>- You can be wild, and still be very wise</p>
        <p>- Bear Market = Time to Build</p>
        <p>- The answer is always no until you ask</p>
        <p>- business before pleasure always</p>
        <p>- ‘your dreams come true when you dont sleep’ - virgil</p>
        <p>- One Day or Day One?</p>
        <p>- “Self confidence is what will help you become the person you are meant to be”</p>
        <p>- We going on a mission</p>
        <p>- if youre afraid of the wolves, dont go into the woods</p>
        <p>- Never ask a fish how to catch it</p>
        <p>- Do wrong and wrong will follow</p>
        <p>- True Explorers Follow the Compass in their Hearts</p>
        <p>- Success is only reserved for those who are willing to sacrifice everything</p>
        <p>- Live a life worth their sacrifice</p>
   </div>
  );
};

export default NotesSecured;





