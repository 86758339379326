import React, { useEffect } from "react";
import './fonts.css'
import ReactGA from 'react-ga4';

const Home = () => {
    useEffect(() => {
        // Track when Home component mounts
        ReactGA.event({
            category: "Page View",
            action: "Home Page View",
            label: "Home Component Mount"
        });
    }, []);

    return (
        <>
            <div className="text-3xl font-semibold mb-4">
                <h1>Abdul Alhusaini</h1>
            </div>
                <div className="text-l">
                    <h1>abdulgo@aol.com</h1>
                </div>
        </>
        
    )
}

export default Home

