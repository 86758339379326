import React from 'react';
import PortfolioTable from './PortfolioTable';

const Portfolio = () => {
  return (
    <div>
          <div className="mb-4 dark:border-gray-700">
          <p className="text-left">$AUR -- DOWN</p>
          <PortfolioTable />
          </div>
   </div>
  );
};

export default Portfolio;
