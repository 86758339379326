import bcryptjs from 'bcryptjs';

// The hashed version of your password

const hashedPassword = '$2a$10$x1L/TwUvpZGvxc8cwkuCm.Bav7dmD87XasYwtUgcX7CdbbhTqiWXW'; 


const verifyPassword = (password) => {
    return bcryptjs.compareSync(password, hashedPassword);
};

export { verifyPassword };